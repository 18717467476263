
const obstaclesConfig = [
    {
        left: 42,
        leftIncrease: -0.5,
        top: 48,
        height: 33,
        width: 33,

    },
    {
        left: 46,
        leftIncrease: 0,
        top: 48,
        height: 33,
        width: 33,
    },
    {
        left: 50,
        leftIncrease: 0.5,
        top: 48,
        height: 33,
        width: 33,
    },

];

export default obstaclesConfig;