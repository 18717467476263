

// % unit
const playerConfig = {
    TOP: 75,
    LEFT: 39,
    WIDTH: 160,
    HEIGHT: 160,
    SPEED: 1,
    ACCELERATION: 0,
};

export default playerConfig;